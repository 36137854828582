import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Components
import { Container, Link, Stack, SvgIcon, Typography } from '@mui/material'
import CtaButton from '../CtaButton'
import Slider from '../Slider'

// Icons
import FaceIcon from '@mui/icons-material/Face'
import FactoryIcon from '@mui/icons-material/Factory'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import GrowthPlantIcon from '../../assets/images/svg/growth-plant.inline.svg'
import ApparelIcon from '../../assets/images/svg/apparel.inline.svg'
import FoursourceLogoMini from '../../assets/images/svg/foursource-logo-mini.inline.svg'

// Styles
import * as S from './CompanyInfo.styles'

const CompanyInfo = ({ content }) => {
  const randomizedSlides = React.useMemo(() => {
    const slides = [...(content?.slides || [])] // Usando spread operator para obter uma cópia do array
    return slides.sort(() => Math.random() - 0.5)
  }, [content?.slides])

  return (
    <S.CompanyInfo mode={content?.mode}>
      <Container maxWidth='xl' sx={{ py: content?.mode === 'dark' && 8, borderRadius: 3 }}>
        {content?.title && <Typography variant='subhead'>{content?.title}</Typography>}
        <Slider
          content={{
            className: 'swiper-case-studies',
            slidesPerView: 1,
            loop: false,
            navigation: true,
            effect: 'fade',
          }}
        >
          {randomizedSlides.map((slide, index) => (
            <div className='slide' key={index}>
              <Typography
                variant='h2'
                sx={{ color: content?.mode === 'dark' ? 'bluishGrey.two' : 'bluishGrey.ten', mb: 3 }}
              >
                {slide?.url ? (
                  <Link target='_blank' rel='noopener' href={slide?.url}>
                    <GatsbyImage image={getImage(slide.logo)} alt={slide.name} />
                  </Link>
                ) : (
                  slide.name
                )}
              </Typography>
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                spacing={{ xs: 1, md: 4 }}
                color='bluishGrey.seven'
                justifyContent='center'
                alignItems='center'
                mb={{ xs: 1, md: 2 }}
              >
                {slide?.location && (
                  <Typography variant='lead' sx={{ display: 'flex', alignItems: 'center' }}>
                    <FmdGoodIcon sx={{ mr: 0.5 }} />
                    {slide.location}
                  </Typography>
                )}
                {slide?.type && (
                  <Typography variant='lead' sx={{ display: 'flex', alignItems: 'center' }}>
                    <FactoryIcon sx={{ mr: 0.5 }} />
                    {slide.type}
                  </Typography>
                )}
                {slide?.employees && (
                  <Typography variant='lead' sx={{ display: 'flex', alignItems: 'center' }}>
                    <FaceIcon sx={{ mr: 0.5 }} />
                    {slide?.employees}
                  </Typography>
                )}
              </Stack>
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                spacing={{ xs: 1, md: 4 }}
                color='bluishGrey.seven'
                justifyContent='center'
                alignItems='center'
                mb={2}
              >
                {slide?.productionFocus && (
                  <Typography variant='lead' sx={{ display: 'flex', alignItems: 'center' }}>
                    <SvgIcon inheritViewBox component={ApparelIcon} sx={{ mr: 0.5, color: 'bluishGrey.seven' }} />
                    {slide.productionFocus}
                  </Typography>
                )}
                {slide?.membership && (
                  <Typography variant='lead' sx={{ display: 'flex', alignItems: 'center' }}>
                    <SvgIcon inheritViewBox component={GrowthPlantIcon} sx={{ mr: 0.5, color: 'bluishGrey.seven' }} />
                    {slide.membership}
                  </Typography>
                )}
                {slide?.memberSince && (
                  <Typography variant='lead' sx={{ display: 'flex', alignItems: 'center' }}>
                    <SvgIcon
                      inheritViewBox
                      component={FoursourceLogoMini}
                      sx={{ mr: 0.5, color: 'bluishGrey.seven' }}
                    />
                    {slide.memberSince}
                  </Typography>
                )}
              </Stack>
              <S.Grid>
                {slide?.items?.map((item, i) => (
                  <S.GridItem key={i}>
                    {typeof item.icon === 'object' ? (
                      <img
                        className='CompanyInfo__Image'
                        src={item.icon.publicURL}
                        alt={item.title + ' ' + item.description}
                      />
                    ) : (
                      <SvgIcon
                        component={item.icon}
                        inheritViewBox
                        sx={{ borderRadius: 1, fontSize: 56, fill: 'none', mb: 1 }}
                      />
                    )}
                    <Typography
                      variant='h3'
                      color={content?.mode === 'dark' ? 'bluishGrey.two' : 'bluishGrey.ten'}
                      mb={1}
                    >
                      {item.title}
                    </Typography>
                    <Typography color='primary'>{item.description}</Typography>
                  </S.GridItem>
                ))}
              </S.Grid>
            </div>
          ))}
        </Slider>
        {content?.button && <CtaButton content={content.button} />}
      </Container>
    </S.CompanyInfo>
  )
}
export default CompanyInfo
