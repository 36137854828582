import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useWindowSize from '../../utils/windowSize'

// Components
import Toolbar from '@mui/material/Toolbar'

// Styles
import './forSuppliersCover.scss'

// Images
import backgroundImg from '../../assets/images/for-suppliers-cover/header-image-suppliers-page.png'
import TrustedBy from '../TrustedBy'

const ForSuppliersCover = () => {
  const responsive = useWindowSize().responsive
  var bgImg = responsive ? { background: '' } : { background: `url('${backgroundImg}') no-repeat right` }
  const { t } = useTranslation()
  return (
    <div className='forSuppliersCover mx-auto'>
      <Toolbar />
      <div
        style={bgImg}
        className={'forSuppliersCoverBgImg d-flex flex-row flex-wrap justify-content-center justify-content-lg-between'}
      >
        <div className='d-flex flex-column mt-s-80 forSuppliersCoverLeft'>
          <h1 className='heading-1 text-grey-2'>{t('The Platform for Textile Professionals.')}</h1>
          <span className='text-grey-7 font-size-18 ln-22 pt-s-16'>
            {t(
              'Foursource is the leading network to connect your business with textile professionals globally. Build your network and grow your business.'
            )}
          </span>
          <a
            href={`${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`}
            className='btn btn-sm btn-primary mt-s-48 mb-md-s-68'
          >
            {t('Join for free')}
          </a>
        </div>
        <div className='d-flex flex-column mt-auto overflowHidden'>
          <TrustedBy mode={'dark'} disposition={'column'} show={true} />
        </div>
      </div>
    </div>
  )
}

export default ForSuppliersCover
