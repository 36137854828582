import * as React from 'react'
import { graphql } from 'gatsby'

// Components
import Benefits from '../components/Benefits'
import Carousel from '../components/Carousel'
import CompanyInfo from '../components/CompanyInfo'
import ForSuppliersCover from '../components/ForSuppliersCover'
import Columns from '../components/Columns'
import LayoutDark from '../components/layoutDark'
import Plans from '../components/Plans'
import Seo from '../components/seo'
import Testimonials from '../components/Testimonials'

// Content
import { logoSlideContent } from '../content/logoSlide'
import { forSuppliersContent } from '../content/forSuppliers'

const ForSuppliersPage = () => {
  return (
    <LayoutDark activePage={'For Suppliers'}>
      <ForSuppliersCover />
      <Benefits mode={'dark'} />
      <Columns content={forSuppliersContent.columns1} />
      <Columns content={forSuppliersContent.columns2} />
      <CompanyInfo content={forSuppliersContent.companyInfo} />
      <Columns content={forSuppliersContent.columns3} />
      <Plans content={forSuppliersContent.plans} />
      <Testimonials mode={'dark'} />
      <Carousel content={logoSlideContent('big', 'white', false).carousel} />
    </LayoutDark>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='For Suppliers'
    description='Foursource is the leading network to connect your business with textile professionals globally. Build your network and grow your business.'
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ForSuppliersPage
