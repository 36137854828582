import styled from 'styled-components'
import companyInfoBackground from '../../assets/images/case-study/case-study-background.jpg'

export const CompanyInfo = styled.section`
  display: flex;
  align-items: center;
  margin: ${(props) => (props.mode === 'dark' ? `64px 16px` : `64px 0`)};
  & .MuiContainer-root {
    text-align: center;
    background-image: ${(props) => props.mode === 'dark' && `url(${companyInfoBackground})`};
    background-size: cover;
    background-position: center 0;
  }
  & .CompanyInfo__Image {
    height: 56px;
    border-radius: 8px;
    margin-bottom: 8px;
  }
`
export const Grid = styled.div.attrs({
  className: 'row gx-0',
})`
  margin-top: 64px !important;
`
export const GridItem = styled.div.attrs({
  className: 'col-md mb-3 mb-md-0',
})`
  padding-left: 8px;
  padding-right: 8px;
  &:last-child {
    margin-bottom: 0 !important;
  }
`
