import * as React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import { theme } from '../theme'
import Scripts from './scripts'

const Layout = ({ children, activePage }) => {
  const bg = { background: '#071027' }
  return (
    <div style={bg}>
      <Scripts />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header activePage={activePage} />
        <main>{children}</main>
        <Footer mode={'dark'} />
      </ThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
